<script setup>
import { ref, onMounted, useSSRContext, watch, defineProps } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'
import KrediflowWorkflow from './Krediflow-Workflow.vue'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

//filters
const filter_tasks = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

//data
const tasks = ref([])
const customers = ref([])
const users = ref([])
const details = ref()

const task_name = ref('')
const task_due_date = ref('')
const task_description = ref('')
const task_user = ref('')
const task_mode = ref("inbox")

const workflow_id = ref()

const get_data = async() => {
    await Promise.all([
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/tasks/get-tasks"),
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/get-customers", {mandate: "impact"}),
    ])
    .then(async([tasks_response, customers_response]) => {
        console.log(customers_response.data)
        customers.value = customers_response.data
        tasks.value = tasks_response.data
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-users')
    .then(response => {
        console.log(response.data)
        users.value = response.data
        loader.value = false
    })
}

//panels
const details_slider = ref(false)
const todo_task_dialog = ref(false)

//menus
const menu_new_task = ref()
const menu_new_task_items = ref([{
    label: 'Task type',
    items: [
        {label: 'Invoice', icon: 'pi pi-file', command: () => create_tasks("invoice")},
        {label: 'To-Do', icon: 'pi pi-check-square', command: () => create_tasks("default")},
        {label: 'Meeting', icon: 'pi pi-calendar', command: () => create_tasks("meeting")}
    ]
}])
const toggle_menu_new_task = (event) => menu_new_task.value.toggle(event)

const task_tabs = ref([
    {name: 'All tasks', icon: 'pi pi-inbox', badge: '0'},
    {name: 'My tasks', icon: 'pi pi-user', badge: '0'}
])

onMounted(() => {
    loader.value = true
    get_data()
    get_users()
    loader.value = false
})

const create_tasks = (type) => {
    console.log("create_tasks", type)
    todo_task_dialog.value = true
}

const save_task = () => {
    console.log("save_task")
    loader.value = true
    let payload = {
        name: task_name.value,
        due_date: task_due_date.value,
        description: task_description.value,
        users: task_user.value,
        type: 'ToDo'
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/partner/tasks/create-task', payload)
    .then(response => {
        console.log(response.data)
        todo_task_dialog.value = false
        get_data()
        loader.value = false
    })
}

const task_details = (event) => {
    console.log(event.data)
    details.value = event.data
    details_slider.value = true
    if (event.data.type == 'Invoice') {
        workflow_id.value = event.data.workflow_id
        task_mode.value = 'invoice'
        task_tabs.value.push({name: event.data.name, icon: 'pi pi-file', badge: '0'})
        console.log("workflow_id", workflow_id.value)
    }
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const copy_to_clipboard = (text) => {
    navigator.clipboard.writeText(text)
    toast.add({severity: 'success', summary: 'Success', detail: 'Copied to clipboard', life: 3000})
}

const get_customer_name = (contact_id) => {
    let customer = customers.value.find(customer => customer.id == contact_id)
    return customer ? customer.name : ''
}

//formatting

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <Toolbar class="mt-2">
            <template #start>
                <Button v-for="task_tab in task_tabs" :key="task_tab" rounded :label="task_tab.name" :icon="task_tab.icon" class="mr-2 bg-black-alpha-90 border-black-alpha-70" @click="task_mode = 'inbox'" />
            </template>
        </Toolbar>
        <div v-if="task_mode == 'invoice'">
            <KrediflowWorkflow v-if="workflow_id" :workflow_id="workflow_id" />
        </div>
        <div v-if="task_mode == 'inbox'">
            <Toolbar class="mt-2">
                <template #start>
                    <span class="p-input-icon-left" style="width: 350px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_tasks['global'].value" placeholder="Search tasks" style="width: 350px" />
                    </span>
                    <span class="mr-4 ml-3 hover:font-bold hover:cursor-pointer">Backlog<Badge value="0" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span class="mr-4 hover:font-bold hover:cursor-pointer">In progress<Badge value="0" class="bg-gray-300 text-gray-600 ml-1" /></span>
                    <span class="mr-4 hover:font-bold hover:cursor-pointer">Done<Badge value="0" class="bg-gray-300 text-gray-600 ml-1" /></span>
                </template>
                <template #end>
                    <Button label="Create Task" icon="pi pi-plus" @click="toggle_menu_new_task" />
                    <Menu ref="menu_new_task" :model="menu_new_task_items" :popup="true" />
                </template>
            </Toolbar>
            <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
                <DataTable v-model:filters="filter_tasks" @row-click="task_details" :value="tasks" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true">
                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="date" header="Date">
                        <template #body="slotProps">
                            {{ format_swiss_date(slotProps.data.date) }}
                        </template>
                    </Column>
                    <Column field="name" header="Task" sortable>
                        <template #body="slotProps">
                            <Avatar icon="pi pi-file" shape="circle" class="mr-2 bg-primary text-white" />
                            <span class="font-bold mr-2">{{slotProps.data.name}}</span>
                        </template>
                    </Column>
                    <Column field="type" header="Type" sortable>
                        <template #body="slotProps">
                            <Chip v-if="slotProps.data.type == 'ToDo'" class="mr-2 mb-1" :label="slotProps.data.type" />
                            <Chip v-if="slotProps.data.type == 'Invoice'" class="mr-2 mb-1 bg-orange-600 text-white" :label="slotProps.data.type" />
                        </template>
                    </Column>
                    <Column field="status" header="Status" sortable>
                        <template #body="slotProps">
                            <Chip v-if="slotProps.data.status == 'open'" class="mr-2 mb-1" label="Backlog" />
                            <Chip v-if="slotProps.data.status == 'progress'" class="mr-2 mb-1  bg-primary text-white" label="In progress" />
                            <Chip v-if="slotProps.data.status == 'done'" class="mr-2 mb-1 bg-green-600 text-white" label="Done" />
                        </template>
                    </Column>
                    <Column field="due_date" header="Due date" sortable>
                        <template #body="slotProps">
                            <span v-if="slotProps.data.due_date && slotProps.data.severity == 'danger'" class="text-red-600 font-bold">{{ format_swiss_date(slotProps.data.due_date) }}</span>
                            <span v-if="slotProps.data.due_date && slotProps.data.severity != 'danger'">{{ format_swiss_date(slotProps.data.due_date) }}</span>
                        </template>
                    </Column>
                    <Column field="users" header="Assigned" sortable>
                        <template #body="slotProps">
                            <AvatarGroup>
                                <Avatar label="A" size="large" shape="circle" />
                                <Avatar label="B" size="large" shape="circle" />
                                <Avatar label="C" size="large" shape="circle" />
                                <Avatar label="+2" shape="circle" size="large" @click="console.log(slotProps)" />
                            </AvatarGroup>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <!-- DETAILS -->
    
    <!-- New ToDo Task -->
    <Dialog v-model:visible="todo_task_dialog" :style="{width: '70%'}" header="New ToDo Task" :modal="true" class="p-fluid z-5">
        <div class="p-fluid formgrid grid">
            <div class="col-12 mt-4">
                <span class="p-float-label">
                    <InputText id="task_name" v-model="task_name" />
                    <label for="task_name">Task</label>
                </span>
            </div>
            <div class="col-6 mt-4">
                <span class="p-float-label">
                    <Calendar id="due_date" dateFormat="dd.mm.yy" v-model="task_due_date" />
                    <label for="due_date">Due date</label>
                </span>
            </div>
            <div class="col-6 mt-4">
                <span class="p-float-label">
                    <Dropdown id="users" v-model="task_user" :options="users" optionLabel="name" optionValue="email" />
                    <label for="users">Assigned users</label>
                </span>
            </div>
            <div class="col-12 mt-4">
                <label for="description">Description</label>
                <Editor id="description" v-model="task_description" class="mt-3 w-full" editorStyle="height: 150px">
                    <template v-slot:toolbar>
                        <span class="ql-formats">
                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            <button class="ql-image" v-tooltip.bottom="'Bild hochladen'"></button>
                        </span>
                    </template>
                </Editor>
            </div>
            <div class="col-12 mt-4">
                <Button label="Save" icon="pi pi-save" class="mr-2 w-auto" @click="save_task" />
                <Button label="Cancel" icon="pi pi-times" class="bg-red-600 text-white border-red-500 w-auto" @click="todo_task_dialog = false" />
            </div>
        </div>
    </Dialog>
</template>