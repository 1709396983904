<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

const loader = ref(false)
const toast = useToast()

const settings = ref([])
const mandate = ref()

//props
const props = defineProps({
    mandate_prop: { type: String }
})

onMounted(() => {
    loader.value = true
    mandate.value = props.mandate_prop
    console.log("settings component mandate", mandate.value)
    get_settings()
    loader.value = false
})

const get_settings = async() => {
    await Promise.all([
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/get-settings", {mandate: mandate.value})
    ])
    .then(async([settings_response]) => {
        console.log(settings_response.data)
        settings.value = settings_response.data
    })
}

const sync_bexio_data = async() => {
    loader.value = true
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/import-bexio-data", {mandate: mandate.value})
    .then(response => {
        toast.add({severity:'success', summary: 'Success', detail: 'Bexio data synced', life: 3000});
    })
    loader.value = false
}

const edit_setting = (event) => {
    console.log(event)
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <Toolbar class="mt-2 w-full">
                <template #start>
                    <Button label="Sync Bexio Data" icon="pi pi-refresh" @click="sync_bexio_data" class="mr-2" />
                </template>
            </Toolbar>
            <div rounded class="overflow-hidden shadow-3 border-200 mt-4 w-full">
                <DataTable editMode="cell" @cell-edit-complete="edit_setting" :value="settings" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true">
                    <Column field="description" header="Setting" style="width: 40%">
                        <template #body="slotProps">
                            <span class="font-bold">{{slotProps.data.description}}</span>
                        </template>
                    </Column>
                    <Column field="name" header="Name" hidden />
                    <Column field="value" header="Value" style="width: 50%">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.type == 'text'">{{slotProps.data.value}}</span>
                            <span v-if="slotProps.data.type == 'password'">************************</span>
                        </template>
                        <template #editor="slotProps">
                            <InputText v-if="slotProps.data.type == 'text'" v-model="slotProps.data.value" />
                        </template>
                    </Column>
                    <Column field="updated_at" header="Edited" style="width: 20%">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.updated_at">{{format_swiss_date_time(slotProps.data.updated_at)}}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
    </BlockUI>
</template>