<script setup>
import { ref, onMounted, watch } from 'vue'
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import Incoming from "./Partner-Journal-Incoming.vue"
import Outgoing from "./Partner-Journal-Outgoing.vue"
import Cashflow from "./Partner-Journal-Cashflow.vue"
import Banking from "./Partner-Journal-Banking.vue"
import Settings from "./Partner-Journal-Settings.vue"
import Partners from "./Partner-Journal-Partners.vue"

const loader = ref(false)
const mandate = ref(null)

const mandates = ref([
    {id: "impact", name: 'Impact Solutions GmbH'},
    {id: "devops", name: 'DEVOPS Machina GmbH'},
])

onMounted(() => {
    loader.value = true
    mandate.value = localStorage.getItem('mandate')
    console.log("mandate", mandate.value)
    loader.value = false
})

const change_mandate = () => {
    console.log("change_mandate", mandate.value)
    localStorage.setItem('mandate', mandate.value)
}

</script>
<template>
    <ConfirmDialog />
    <Toolbar class="mb-3">
        <template #start>
            <Dropdown placeholder="Select Mandate" v-model="mandate" :options="mandates" optionLabel="name" optionValue="id" class="w-auto mr-4" @change="change_mandate()" style="width: 400px" />
        </template>
    </Toolbar>
    <div class="p-fluid formgrid grid">
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
                <template #header>
                    <i class="pi pi-arrow-down-left mr-2"></i>
                    <span class="mr-3">Incoming</span>
                </template>
                <Incoming v-if="mandate == 'devops' || mandate == 'impact'" :mandate_prop="mandate" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-arrow-up-right mr-2"></i>
                    <span class="mr-3">Outgoing</span>
                </template>
                <Outgoing v-if="mandate == 'devops' || mandate == 'impact'" :mandate_prop="mandate" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-building mr-2"></i>
                    <span class="mr-3">Banking</span>
                </template>
                <Banking v-if="mandate == 'devops' || mandate == 'impact'" :mandate_prop="mandate" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-money-bill mr-2"></i>
                    <span class="mr-3">Cashflow</span>
                </template>
                <Cashflow v-if="mandate" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-users mr-2"></i>
                    <span class="mr-3">Partners</span>
                </template>
                <Partners v-if="mandate == 'devops' || mandate == 'impact'" :mandate_prop="mandate" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-cog mr-2"></i>
                    <span class="mr-3">Settings</span>
                </template>
                <Settings v-if="mandate == 'devops' || mandate == 'impact'" :mandate_prop="mandate" />
            </TabPanel>
        </TabView>
    </div>
</template>