<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

//props
const props = defineProps({
    mandate_prop: { type: String }
})

//filters
const filter_banking = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

//data
const banking = ref([])
const mandate = ref()
const customers = ref([])

const get_data = async() => {
    await Promise.all([
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/banking/get-transactions", {mandate: mandate.value}),
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/get-customers", {mandate: mandate.value})
    ])
    .then(async([transactions_response, customers_response]) => {
        console.log(transactions_response.data)
        customers.value = customers_response.data
        banking.value = transactions_response.data
    })
}

//panels
const camt_import_slider = ref(false)

//menus
const menu_import = ref()
const menu_import_items = ref([{
    label: 'Sources',
    items: [
        {label: 'Bexio', icon: 'pi pi-download', command: () => camt_import()},
    ]
}])
const toggle_menu_import = (event) => menu_import.value.toggle(event)

onMounted(() => {
    loader.value = true
    mandate.value = props.mandate_prop
    console.log("component mandate", mandate.value)
    get_data()
    loader.value = false
})

//methods
const camt_import = () => {
    loader.value = true
    camt_import_slider.value = true
    loader.value = false
}

//formatting
const get_customer_name = (contact_id) => {
    let customer = customers.value.find(customer => customer.id == contact_id)
    return customer ? customer.name : ''
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <Toolbar class="mt-2">
            <template #start>
                <span class="p-input-icon-left" style="width: 400px">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_banking['global'].value" placeholder="Search results" />
                </span>
            </template>
            <template #end>
                <Button label="Import" icon="pi pi-download" @click="toggle_menu_import" />
                <Menu ref="menu_import" :model="menu_import_items" :popup="true" />
            </template>
        </Toolbar>
        <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
            <DataTable  v-model:filters="filter_banking" :value="banking" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true">
                <Column field="date" header="Date">
                    <template #body="slotProps">
                        {{ format_swiss_date(slotProps.data.date) }}
                    </template>
                </Column>
                <Column field="type" header="Type">
                    <template #body="slotProps">
                        <Badge v-if="slotProps.data.type == 'CRDT'" value="Credit" class="bg-green-600 text-white" />
                        <Badge v-if="slotProps.data.type == 'DBIT'" value="Debit" class="bg-red-600 text-white" />
                    </template>
                </Column>
                <Column field="id" header="Transaction-ID" sortable>
                    <template #body="slotProps">
                        <span class="ml-2 font-medium">{{slotProps.data.id}}</span>
                    </template>
                </Column>
                <Column field="amount" header="Amount" sortable>
                    <template #body="slotProps">
                        <span v-if="slotProps.data.type == 'CRDT'" class="text-green-600 font-bold">{{ format_currency(slotProps.data.amount) }}</span>
                        <span v-if="slotProps.data.type == 'DBIT'" class="text-red-600 font-bold">{{ format_currency(slotProps.data.amount) }}</span>
                    </template>
                </Column>
                <Column field="description" header="Description" />
                <Column field="invoices" header="Invoices">
                    <template #body="slotProps">
                        <Avatar v-if="slotProps.data.invoice_id" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="'Banking wurde verknüpft'" />
                        <Avatar v-if="!slotProps.data.invoice_id" icon="pi pi-times" class="mr-2 bg-red-500 text-white" shape="circle" v-tooltip.top="'Nicht mit Banking verknüpft!'" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </BlockUI>
</template>