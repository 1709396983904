<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const partners = ref([])
const mandate = ref()
const customers = ref([])
const details = ref([])

const details_slider = ref(false)

//props
const props = defineProps({
    mandate_prop: { type: String }
})

onMounted(() => {
    loader.value = true
    mandate.value = props.mandate_prop
    console.log("settings component mandate", mandate.value)
    get_partners()
    loader.value = false
})

const get_partners = async() => {
    await Promise.all([
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/get-partners"),
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/get-customers", {mandate: mandate.value})
    ])
    .then(async([partners_response, customers_response]) => {
        console.log(customers_response.data)
        partners.value = partners_response.data
        customers.value = customers_response.data
    })
}

const edit_partner = (event) => {
    console.log(event)
    details.value = event.data
    details_slider.value = true
}

const save_partner = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/save-partner", details.value)
    .then(response => {
        loader.value = false
        details_slider.value = false
        toast.add({severity:'success', summary:'Success', detail:'Partner saved'})
        get_partners()
    })
}

const get_customer_name = (contact_id) => {
    let customer = customers.value.find(customer => customer.id == contact_id)
    return customer ? customer.name : ''
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const copy_to_clipboard = (text) => {
    navigator.clipboard.writeText(text)
    .then(() => {
        toast.add({severity:'success', summary:'Success', detail:'Copied to clipboard'});
    })
    .catch((error) => {
        toast.add({severity:'error', summary:'Error', detail:'Could not copy to clipboard'});
    });
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <DataTable editMode="cell" @row-click="edit_partner" :value="partners" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true">
            <Column field="user_firstname" header="Name">
                <template #body="slotProps">
                    <span>{{slotProps.data.user_firstname}}</span>
                </template>
            </Column>
            <Column field="user_lastname" header="Surname">
                <template #body="slotProps">
                    <span>{{slotProps.data.user_lastname}}</span>
                </template>
            </Column>
            <Column field="user_email" header="Email">
                <template #body="slotProps">
                    <span>{{slotProps.data.user_email}}</span>
                </template>
            </Column>
            <Column field="user_mobile" header="Phone">
                <template #body="slotProps">
                    <span>{{slotProps.data.user_mobile}}</span>
                </template>
            </Column>
            <Column field="user_type" header="Type">
                <template #body="slotProps">
                    <Chip :label="slotProps.data.user_type" class="mr-2 mb-1" />
                </template>
            </Column>
            <Column field="user_customers" header="Customers">
                <template #body="slotProps">
                    <Chip v-for="(key) in slotProps.data.user_customers" :key="key" :label="get_customer_name(key)" class="mr-2 mb-1" />
                </template>
            </Column>
            <Column field="user_commission" header="Commission">
                <template #body="slotProps">
                    <span>{{ format_currency_without_prefix(slotProps.data.user_commission) }}</span>
                </template>
            </Column>
        </DataTable>
    </BlockUI>
    <!-- DETAILS -->
    <Sidebar v-model:visible="details_slider" position="right" style="width: 50%">
        <TabView class="col-12">
            <TabPanel>
                <template #header>
                    <i class="pi pi-user mr-2"></i>
                    <span class="mr-3">User Infos</span>
                </template>
                <div class="col-12 grid">
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Name
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_firstname" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Surname
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_lastname" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Email
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_email" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Phone
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_mobile" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Type
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <Dropdown v-model="details.user_type" :options="[{label: 'Admin', value: 'Admin'}, {label: 'Partner', value: 'Partner'}]" optionLabel="label" optionValue="value" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Customers
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <MultiSelect v-model="details.user_customers" :options="customers" filter optionLabel="name" optionValue="id" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                        Commission
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputNumber v-model="details.user_commission" class="w-full" suffix=" %" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-address-book mr-2"></i>
                    <span class="mr-3">Address</span>
                </template>
                <div class="col-12 grid">
                    <div class="col-12 p-2 pt-3 font-bold border-blue-100 border-solid border-bottom-1 m-0 align-content-center text-lg">
                        Billing Address
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        Company name
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_company" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        Street
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_street" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        ZIP
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_zip" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        City
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_city" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        Country
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_country" class="w-full" />
                    </div>
                    <div class="col-12 p-2 pt-3 font-bold border-blue-100 border-solid border-bottom-1 m-0 align-content-center text-lg">
                        Bank details
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        IBAN
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_iban" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        BIC
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_bic" class="w-full" />
                    </div>
                    <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                        Bank name
                    </div>
                    <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                        <InputText v-model="details.user_bank" class="w-full" />
                    </div>
                </div>
            </TabPanel>
        </TabView>
        <div class="col-12 grid">
            <div class="col-12 p-2 ml-3">
                <Button label="Save" icon="pi pi-save" @click="save_partner" class="bg-green-600 text-white border-green-500 mr-2" />
                <Button label="Cancel" icon="pi pi-times" @click="details_slider = false" class="bg-red-600 text-white border-red-500" />
            </div>
        </div>
    </Sidebar>
</template>