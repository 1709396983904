<script setup>
import { ref, onMounted, useSSRContext, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const filter_emails = ref({global: { value: null, matchMode: FilterMatchMode.CONTAINS }})

const email = ref({})
const save_email = ref({})
const emails = ref([])
const users = ref([])
const organization = ref(null)
const organizations = ref([])
const filtered_organizations = ref([])
const new_contact = ref(false)

const view_email_slider = ref(false)
const save_email_slider = ref(false)

const document_classifications = [
    {label: 'Angebot', value: 'Angebot'},
    {label: 'Rechnung', value: 'Rechnung'},
    {label: 'Lieferschein', value: 'Lieferschein'},
    {label: 'Bestellung', value: 'Bestellung'},
    {label: 'Spesen', value: 'Spesen'},
    {label: 'Anmeldeformular', value: 'Anmeldeformular'},
    {label: 'Mitarbeiterliste', value: 'Mitarbeiterliste'},
    {label: 'Vertrag', value: 'Vertrag'},
    {label: 'Sonstiges', value: 'Sonstiges'}
]

onMounted( async () => {
    get_inbox()
    get_users()
})

const get_inbox = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-inbox')
    .then(response => {
        console.log(response.data)
        emails.value = response.data
        loader.value = false
    })
}

const get_pipedrive_organizations = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-pipedrive-organizations')
    .then(response => {
        console.log(response.data)
        organizations.value = response.data
        loader.value = false
    })
}

const get_emails = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-emails')
    .then(response => {
        get_inbox()
        loader.value = false
    })
}

const get_users = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/get-users')
    .then(response => {
        console.log(response.data)
        users.value = response.data
        loader.value = false
    })
}

const delete_email = (workflow_id) => {
    confirm.require({
        message: 'Möchten Sie diese E-Mail wirklich löschen?',
        header: 'Bestätigung',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        acceptClass: 'p-button-danger',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/delete-email', {workflow_id: workflow_id})
            .then(response => {
                get_inbox()
                toast.add({severity: 'success', summary: 'E-Mail wurde erfolgreich gelöscht', life: 3000})
                loader.value = false
            })
        }
    })
}

const edit_email = (event) => {
    loader.value = true
    console.log("event", event)
    emails.value[event.index].owner = event.newData.owner
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/edit-email', event.data)
    .then(response => {
        toast.add({severity: 'success', summary: 'E-Mail Bearbeiter wurde erfolgreich angepasst', life: 3000})
        get_inbox()
        loader.value = false
    })
}

const view_email = (data) => {
    email.value = data
    email.value.formatted_dc = format_swiss_date_time(data.dc)
    view_email_slider.value = true
}

const process_email = (data) => {
    loader.value = true
    get_pipedrive_organizations()
    save_email.value = data
    save_email.value.tasks = []
    save_email_slider.value = true
    new_contact.value = {"organization": "", contacts: []}
    loader.value = false
}

const search_organizations = (event) => {
    //contains filter organizations
    filtered_organizations.value = organizations.value.filter((organization) => {
        return organization.name.toLowerCase().includes(event.query.toLowerCase())
    })
}

const delete_document = (data) => {
    save_email.value.attachments.splice(data.index, 1)
    toast.add({severity: 'success', summary: 'Dokument wurde erfolgreich gelöscht', life: 3000})
}

const edit_documents = (event) => {
    save_email.value.attachments[event.index].filename = event.newData.filename
    save_email.value.attachments[event.index].classification = event.newData.classification
    toast.add({severity: 'success', summary: 'Dokument wurde erfolgreich angepasst', life: 3000})
}

const check_documents_completion = () => {
    let documents = save_email.value.attachments
    //check if all documents have a classification or if field classification exists
    let missing_documents = documents.filter(document => !document.classification || document.classification === '')
    console.log("missing_documents", missing_documents)
    return missing_documents.length !== 0
}

const delete_task = (data) => {
    save_email.value.tasks.splice(data.index, 1)
    toast.add({severity: 'success', summary: 'Aufgabe wurde erfolgreich gelöscht', life: 3000})
}

const edit_tasks = (event) => {
    console.log("task event", event)
    save_email.value.tasks[event.index].task = event.newData.task
    save_email.value.tasks[event.index].owner = event.newData.owner
    toast.add({severity: 'success', summary: 'Aufgabe wurde erfolgreich angepasst', life: 3000})
}

const reorder_tasks = (event) => {
    save_email.value.tasks = event.value
    toast.add({severity: 'success', summary: 'Aufgaben wurden erfolgreich neu sortiert', life: 3000})
}

const add_task = (data) => {
    save_email.value.tasks.push({task: '', owner: ''})
    toast.add({severity: 'success', summary: 'Neue Aufgabe wurde erfolgreich hinzugefügt', life: 3000})
}

const edit_contacts = (event) => {
    console.log("contact event", event)
    new_contact.value.contacts[event.index].name = event.newData.name
    new_contact.value.contacts[event.index].email = event.newData.email
    new_contact.value.contacts[event.index].phone = event.newData.phone
    toast.add({severity: 'success', summary: 'Kontakt wurde erfolgreich angepasst', life: 3000})
}

const add_contact = (data) => {
    new_contact.value.contacts.push({name: '', email: '', phone: ''})
    toast.add({severity: 'success', summary: 'Neuer Kontakt wurde erfolgreich hinzugefügt', life: 3000})
}

const create_organization_and_contacts = () => {
    //loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/prosperita/create-pipedrive-organization-and-persons', {organization: new_contact.value.organization, contacts: new_contact.value.contacts, attachments: save_email.value.attachments, tasks: save_email.value.tasks})
    .then(response => {
        console.log(response.data)
        organization.value = response.data.organization_id
        //loader.value = false
    })
}

const get_user_name = (email) => {
    let user = users.value.find(user => user.email === email)
    return user ? user.name : ''
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const format_bytes_to_mb = (bytes) => {
    return (bytes / 1024 / 1024).toFixed(2) + ' MB'
}

const open_url = (url) => {
    window.open(url, '_blank')
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText v-model="filter_emails['global'].value" placeholder="Suche" class="w-full" />
            </span>
            <Button label="Aktualisieren" icon="pi pi-refresh" class="p-button-success" @click="get_emails" />
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:filters="filter_emails" editMode="cell" @cell-edit-complete="edit_email" :value="emails" :rows="200" responsiveLayout="scroll"  :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="dc" header="Datum" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-envelope" shape="circle" class="bg-primary text-white mr-2" />
                    <span class="font-bold">{{ format_swiss_date_time(slotProps.data.dc) }}</span>
                </template>
            </Column>
            <Column field="email_from" header="Absender">
                <template #body="slotProps">
                    <span>{{slotProps.data.email_from}}</span>
                </template>
            </Column>
            <Column field="email_subject" header="Betreff">
                <template #body="slotProps">
                    <span>{{slotProps.data.email_subject}}</span>
                </template>
            </Column>
            <Column field="email_body_text" header="Nachricht">
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-primary" icon="pi pi-eye" @click="view_email(slotProps.data)" />
                </template>
            </Column>
            <Column field="owner" header="Bearbeiter">
                <template #body="slotProps">
                    <Chip :label="get_user_name(slotProps.data.owner)" />
                </template>
                <template #editor="{ data, field }">
                    <Dropdown v-model="data[field]" :options="users" optionLabel="name" optionValue="email" />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button class="p-button-rounded p-button-success mr-2" icon="pi pi-cog" @click="process_email(slotProps.data)" />
                    <Button class="p-button-rounded p-button-danger" icon="pi pi-trash" @click="delete_email(slotProps.data.workflow_id)" />
                </template>
            </Column>
        </DataTable>
    </div>
    <!---------------------------------------------------------------->
    <!-------------------------- Dialogs ----------------------------->
    <!---------------------------------------------------------------->
    <!---------------------------------------------------------------->
    <!------------------------- View Email --------------------------->
    <!---------------------------------------------------------------->
    <Sidebar header="E-Mail" v-model:visible="view_email_slider" position="left" style="width: 50%">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-4">
                <div class="font-bold p2">E-Mail Meta</div>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputText id="email_from" v-model="email.email_from" class="w-full" />
                    <label for="email_from">Absender</label>
                </span>
            </div>
            <div class="field col-6 mt-4">
                <span class="p-float-label">
                    <InputText id="dc" v-model="email.formatted_dc" class="w-full" />
                    <label for="dc">Datum</label>
                </span>
            </div>
            <div class="field col-12 mt-4">
                <span class="p-float-label">
                    <InputText id="subject" v-model="email.email_subject" class="w-full" />
                    <label for="subject">Betreff</label>
                </span>
            </div>
            <div class="field col-12 mt-2">
                <div class="font-bold p2">Inhalt</div>
            </div>
            <div class="field col-12 mt-2">
                <Textarea id="body" v-model="email.email_body_text" class="w-full" style="height: 400px" />
            </div>
            <div class="field col-12 mt-2" v-if="email.attachments">
                <div class="font-bold p2">Attachments</div>
            </div>
            <div class="field col-12 mt-1" v-if="email.attachments">
                <div class="p-d-flex p-flex-wrap">
                    <div v-for="(attachment, key) in email.attachments" :key="key" class="flex flex-wrap align-items-center mr-2 mt-2">
                        <Avatar icon="pi pi-paperclip" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold mr-2 cursor-pointer" @click="open_url(attachment.url)">{{attachment.filename}}</span>
                        <span>({{format_bytes_to_mb(attachment.size)}})</span>
                    </div>
                </div>
            </div>
            <div class="field col-12 mt-4">
                <Button label="E-Mail antworten" icon="pi pi-reply" class="mr-2" />
                <Dropdown v-model="email.owner" :options="users" optionLabel="name" optionValue="email" placeholder="Bearbeiter auswählen" />
            </div>
        </div>
    </Sidebar>
    <Sidebar header="E-Mail ablegen" v-model:visible="save_email_slider" position="right" style="width: 50%">
        <TabView class="w-full mt-4">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-folder" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">E-Mail ablegen</span>
                    </div>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-4">
                        <span class="p-float-label">
                            <AutoComplete id="organization" v-model="organization" :suggestions="filtered_organizations" optionLabel="name" @complete="search_organizations" forceSelection dropdown class="w-full" /> 
                            <label for="organization">Firma auswählen</label>
                        </span>
                    </div>
                    <div class="field col-12 mt-2">
                        <Button icon="pi pi-eye" class="p-button-rounded" label="E-Mail ansehen" @click="view_email(save_email)" />
                    </div>
                    <div class="field col-12 mt-3">
                        <div class="font-bold p2">Dokumente ablegen</div>
                    </div>
                    <div class="field col-12 mt-2">
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_documents" :value="save_email.attachments" responsiveLayout="scroll" :rowHover="true">
                                <Column field="filename" header="Dateiname" style="width: 50%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.filename" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.filename" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="classification" header="Typ" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Dropdown v-model="slotProps.data.classification" :options="document_classifications" optionLabel="label" optionValue="value" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chip :label="slotProps.data.classification" />
                                    </template>
                                </Column>
                                <Column style="width: 20%">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-eye" class="p-button-text p-button-rounded mr-2" @click="open_url(slotProps.data.url)" />
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_document(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-upload" class="p-button-text p-button-rounded" @click="open_url(slotProps.data.url)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="field col-12 mt-3">
                        <div class="font-bold p2">Aufgaben erstellen</div>
                    </div>
                    <div class="field col-12 mt-2">
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_tasks" :value="save_email.tasks" responsiveLayout="scroll" :rowHover="true"  @rowReorder="reorder_tasks">
                                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                                <Column field="task" header="Augabe" style="width: 50%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.task" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.task" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="owner" header="Bearbeiter" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Dropdown v-model="slotProps.data.owner" :options="users" optionLabel="name" optionValue="email" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chip :label="get_user_name(slotProps.data.owner)" />
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_task(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-plus" class="p-button-text p-button-rounded" @click="add_task(slotProps)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="field col-12 mt-4">
                        <div class="p-3">
                            <div class="text-red-600" v-if="check_documents_completion()">Bitte alle Dokumente klassifizieren.</div>
                            <div class="text-red-600" v-if="!organization">Bitte eine Firma auswählen</div>
                        </div>
                        <Button :disabled="check_documents_completion() ? true : false" label="E-Mail ablegen" icon="pi pi-save" class="mr-2 w-auto p-button-success" />
                        <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="save_email_slider = false" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar icon="pi pi-user-plus" shape="circle" class="bg-primary text-white mr-2" />
                        <span class="font-bold white-space-nowrap">Neuen Kontakt erstellen</span>
                    </div>
                </template>
                <div class="col-12 formgrid grid">
                    <div class="field col-12 mt-2">
                        <span class="p-float-label">
                            <InputText id="name" v-model="new_contact.organization" class="w-full" />
                            <label for="name">Firmenname</label>
                        </span>
                    </div>
                    <div class="field col-12 mt-3">
                        <div class="font-bold p2">Kontaktpersonen</div>
                    </div>
                    <div class="field col-12 mt-2">
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_contacts" :value="new_contact.contacts" responsiveLayout="scroll" :rowHover="true">
                                <Column field="name" header="Name" style="width: 30%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.name" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.name" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="email" header="Emails" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Chips v-model="slotProps.data.email" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chips v-model="slotProps.data.email" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="phone" header="Telefon" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Chips v-model="slotProps.data.phone" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chips v-model="slotProps.data.phone" class="w-full" />
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_contact(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-plus" class="p-button-text p-button-rounded" @click="add_contact(slotProps)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="field col-12 mt-3">
                        <div class="font-bold p2">Dokumente ablegen</div>
                    </div>
                    <div class="field col-12 mt-2">
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_documents" :value="save_email.attachments" responsiveLayout="scroll" :rowHover="true">
                                <Column field="filename" header="Dateiname" style="width: 50%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.filename" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.filename" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="classification" header="Typ" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Dropdown v-model="slotProps.data.classification" :options="document_classifications" optionLabel="label" optionValue="value" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chip :label="slotProps.data.classification" />
                                    </template>
                                </Column>
                                <Column style="width: 20%">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-eye" class="p-button-text p-button-rounded mr-2" @click="open_url(slotProps.data.url)" />
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_document(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-upload" class="p-button-text p-button-rounded" @click="open_url(slotProps.data.url)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="field col-12 mt-3">
                        <div class="font-bold p2">Aufgaben erstellen</div>
                    </div>
                    <div class="field col-12 mt-2">
                        <div rounded class="overflow-hidden shadow-4 rounded border-200">
                            <DataTable editMode="cell" @cell-edit-complete="edit_tasks" :value="save_email.tasks" responsiveLayout="scroll" :rowHover="true"  @rowReorder="reorder_tasks">
                                <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
                                <Column field="task" header="Augabe" style="width: 50%" class="overflow-hidden">
                                    <template #editor="slotProps">
                                        <InputText v-model="slotProps.data.task" class="w-full" />
                                    </template>
                                    <template #body="slotProps">
                                        <InputText v-model="slotProps.data.task" class="w-full" />
                                    </template>
                                </Column>
                                <Column field="owner" header="Bearbeiter" style="width: 30%">
                                    <template #editor="slotProps">
                                        <Dropdown v-model="slotProps.data.owner" :options="users" optionLabel="name" optionValue="email" />
                                    </template>
                                    <template #body="slotProps">
                                        <Chip :label="get_user_name(slotProps.data.owner)" />
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps">
                                        <Button icon="pi pi-trash" class="p-button-text p-button-rounded p-button-danger" @click="delete_task(slotProps)" />
                                    </template>
                                    <template #header="slotProps">
                                        <Button icon="pi pi-plus" class="p-button-text p-button-rounded" @click="add_task(slotProps)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                    <div class="field col-12 mt-4">
                        <Button label="Kontakt erstellen & E-Mail ablegen" icon="pi pi-save" class="mr-2 w-auto p-button-success" @click="create_organization_and_contacts()" />
                        <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="save_email_slider = false" />
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </Sidebar>
</template>
    