<script setup>
import { ref, onMounted, useSSRContext, watch, defineProps } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import Toast from 'primevue/toast'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

//props
const props = defineProps({
    mandate_prop: { type: String }
})

//filters
const filter_journal = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_bexio_import = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_bank_transactions = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_customers = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})

//data
const incoming = ref([])
const mandate = ref()
const bexio_import_data = ref([])
const customers = ref([])
const details = ref()
const bank_transactions = ref([])
const transaction_id = ref()
const customer_id = ref()
const partners = ref([])

const get_data = async() => {
    await Promise.all([
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/incoming/get-transactions", {mandate: mandate.value}),
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/get-customers", {mandate: mandate.value}),
        axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/get-partners")
    ])
    .then(async([transactions_response, customers_response, partners_response]) => {
        console.log(customers_response.data)
        customers.value = customers_response.data
        incoming.value = transactions_response.data
        partners.value = partners_response.data
    })
}

//panels
const bexio_import_slider = ref(false)
const details_slider = ref(false)
const bank_transactions_slider = ref(false)
const customers_slider = ref(false)

//menus
const menu_import = ref()
const menu_import_items = ref([{
    label: 'Sources',
    items: [
        {label: 'Bexio', icon: 'pi pi-download', command: () => bexio_import()},
    ]
}])
const toggle_menu_import = (event) => menu_import.value.toggle(event)

onMounted(() => {
    loader.value = true
    mandate.value = props.mandate_prop
    console.log("component mandate", mandate.value)
    get_data()
    loader.value = false
})

//methods
const bexio_import = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/incoming/bexio-import", {mandate: mandate.value})
    .then(async(response) => {
        console.log(response.data)
        bexio_import_data.value = response.data
        bexio_import_slider.value = true
        loader.value = false
    })
}

const create_bexio_transaction = (data) => {
    loader.value = true
    let payload = {
        mandate: mandate.value,
        transaction_type: "incoming",
        amount: data.total,
        date: data.is_valid_from,
        description: data.title,
        currency: data.currency_id,
        invoice_name: data.document_nr,
        bexio_id: data.id,
        bank_id: null,
        customer: data.contact_id,
        account: null
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/incoming/create-transaction", payload)
    .then(async(response) => {
        get_data()
        bexio_import()
        toast.add({severity: 'success', summary: 'Success', detail: 'Transaction created', life: 3000})
        loader.value = false
    })
}

const get_bank_transactions = (id) => {
    loader.value = true
    invoice_id.value = id
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/incoming/get-bank-transactions", {mandate: mandate.value})
    .then(async(response) => {
        bank_transactions.value = response.data
        filter_bank_transactions.value['global']['value'] = String(parseFloat(String(details.value.amount)).toFixed(2))
        bank_transactions_slider.value = true
        loader.value = false
    })
}

const link_bank_transaction = (transaction_id, transaction_date, invoice_id) => {
    loader.value = true
    let payload = {
        "mandate": mandate.value,
        "transaction_id": transaction_id,
        "invoice_id": invoice_id,
        "transaction_date": transaction_date
    }
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/incoming/link-bank-transaction", payload)
    .then(async(response) => {
        get_data()
        get_bank_transactions(invoice_id)
        toast.add({severity: 'success', summary: 'Success', detail: 'Transaction linked', life: 3000})
        loader.value = false
        bank_transactions_slider.value = false
        details_slider.value = false
    })
}

const transaction_details = (event) => {
    details.value = event.data
    console.log("details event", event)
    details_slider.value = true
}

const get_customers = (id) => {
    transaction_id.value = id
    customer_id.value = details.value.customer
    customers_slider.value = true
}

const link_customer_invoice = (customer_id, transaction_id) => {
    confirm.require({
        message: 'Are you sure you want to link this customer?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, link',
        rejectLabel: 'No',
        accept: () => {
            loader.value = true
            let payload = {
                "mandate": mandate.value,
                "customer_id": customer_id,
                "transaction_id": transaction_id
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/partner/incoming/link-customer-invoice", payload)
            .then(async(response) => {
                get_data()
                toast.add({severity: 'success', summary: 'Success', detail: 'Customer linked', life: 3000})
                loader.value = false
                customers_slider.value = false
                details_slider.value = false
            })
        }
    })
}


const bexio_id_exists = (bexio_id) => {
    let transaction = incoming.value.find(transaction => transaction.bexio_id == bexio_id)
    return transaction ? true : false
}

const transaction_id_exists = (transaction_id) => {
    let transaction = incoming.value.find(transaction => transaction.banking == transaction_id)
    return transaction ? true : false
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const copy_to_clipboard = (text) => {
    navigator.clipboard.writeText(text)
    toast.add({severity: 'success', summary: 'Success', detail: 'Copied to clipboard', life: 3000})
}

const get_customer_name = (contact_id) => {
    let customer = customers.value.find(customer => customer.id == contact_id)
    return customer ? customer.name : ''
}


//formatting

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}
</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <Toolbar class="mt-2">
            <template #start>
                <span class="p-input-icon-left" style="width: 400px">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_journal['global'].value" placeholder="Search results" />
                </span>
            </template>
            <template #end>
                <Button label="Import" icon="pi pi-download" @click="toggle_menu_import" />
                <Menu ref="menu_import" :model="menu_import_items" :popup="true" />
            </template>
        </Toolbar>
        <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
            <DataTable v-model:filters="filter_journal" @row-click="transaction_details" :value="incoming" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true">
                <Column field="date" header="Date">
                    <template #body="slotProps">
                        {{ format_swiss_date(slotProps.data.date) }}
                    </template>
                </Column>
                <Column field="preview_url">
                    <template #body="slotProps">
                        <div class="overflow-hidden shadow-2 w-9rem h-4rem">
                            <img v-if="slotProps.data.preview_url" :src="slotProps.data.preview_url" v-tooltip.right="{ value: '<img src=\'' + slotProps.data.preview_url + '\' />', escape: false}" />
                        </div>
                    </template>
                </Column>
                <Column field="invoice_name" header="Invoice" sortable>
                    <template #body="slotProps">
                        <Chip class="pl-0 pr-3 mr-2">
                            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-file" /></span>
                            <span class="ml-2 font-medium">{{slotProps.data.invoice_name}}</span>
                        </Chip>
                        <span class="font-bold mr-2">{{get_customer_name(slotProps.data.customer)}}</span>
                    </template>
                </Column>
                <Column field="description" header="Description" />
                <Column field="amount" header="Amount" sortable>
                    <template #body="slotProps">
                        {{ format_currency(slotProps.data.amount) }}
                    </template>
                </Column>
                <Column field="banking" header="Status">
                    <template #body="slotProps">
                        <Avatar v-if="slotProps.data.banking" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="'Bank transaction is linked'" />
                        <Avatar v-if="!slotProps.data.banking" icon="pi pi-times" class="mr-2 bg-red-500 text-white" shape="circle" v-tooltip.top="'Bank transaction is not yet linked!'" />
                    </template>
                </Column>
                <Column field="bexio_id" header="ERP">
                    <template #body="slotProps">
                        <Avatar v-if="slotProps.data.bexio_id" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="'ERP is linked'" />
                        <Avatar v-if="!slotProps.data.bexio_id" icon="pi pi-times" class="mr-2 bg-red-500 text-white" shape="circle" v-tooltip.top="'ERP is not yet linked!'" />
                    </template>
                </Column>
                <Column field="file_url" header="Receipt">
                    <template #body="slotProps">
                        <Avatar v-if="slotProps.data.file_url" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="'Receipt is present'" />
                        <Avatar v-if="!slotProps.data.file_url" icon="pi pi-times" class="mr-2 bg-red-500 text-white" shape="circle" v-tooltip.top="'Receipt is not yet present!'" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <!-- BEXIO IMPORT -->
    <Sidebar v-model:visible="bexio_import_slider" position="right" style="width: 75%">
        <div class="col-12 formgrid grid">
            <Toolbar class="mt-2 w-full">
                <template #start>
                    <span class="p-input-icon-left" style="width: 400px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_bexio_import['global'].value" placeholder="Search" style="width: 400px" />
                    </span>
                </template>
            </Toolbar>
            <div rounded class="overflow-hidden shadow-3 border-200 mt-4 w-full">
                <DataTable class="w-full" v-model:filters="filter_bexio_import" :value="bexio_import_data" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,500]" :paginator="true">
                    <Column field="is_valid_from" header="Date" sortable>
                        <template #body="slotProps">
                            {{ format_swiss_date(slotProps.data.is_valid_from) }}
                        </template>
                    </Column>
                    <Column field="document_nr" header="Invoice-Nr" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{ slotProps.data.document_nr }}</span>
                        </template>
                    </Column>
                    <Column field="title" header="Title" style="width: 40%" />
                    <Column field="debtor" header="Debtor">
                        <template #body="slotProps">
                            {{ get_customer_name(slotProps.data.contact_id) }}
                        </template>
                    </Column>
                    <Column field="total" header="Total" sortable>
                        <template #body="slotProps">
                            {{ format_currency(slotProps.data.total) }}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button v-if="!bexio_id_exists(slotProps.data.id)" icon="pi pi-plus" class="p-button-rounded p-button-primary" @click="create_bexio_transaction(slotProps.data)" v-tooltip.top="'Import invoice'" />
                            <Avatar v-if="bexio_id_exists(slotProps.data.id)" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="'Rechnung wurde verknüpft'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </Sidebar>
    <!-- DETAILS -->
    <Sidebar v-model:visible="details_slider" position="right" style="width: 50%">
        <div class="col-12 grid">
            <div class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <Image preview class="w-12rem" :src="details.preview_url" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Invoice
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span class="font-bold">{{details.invoice_name}}</span>
                <i @click="copy_to_clipboard(details.invoice_name)" v-tooltip.top="'Copy to clipboard'" class="pi pi-copy ml-4 text-primary cursor-pointer" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Date
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span>{{format_swiss_date(details.date)}}</span>
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Receipt
            </div>         
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary" @click="open_url(details.file_url)" v-tooltip.top="'Upload attachments'" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Description
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span>{{details.description}}</span>
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Amount
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span class="font-bold">{{format_currency(details.amount)}}</span>
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Creditor
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0 align-content-center">
                <Chip v-if="get_customer_name(details.customer)" :label="get_customer_name(details.customer)" icon="pi pi-check-circle" class="mr-2 mb-1 font-bold" />
                <Avatar v-if="!get_customer_name(details.customer)" icon="pi pi-times" class="mr-2 bg-red-500 text-white mr-2 mb-2" shape="circle" v-tooltip.top="'Not yet linked with a customer!'" />
                <Button v-if="get_customer_name(details.customer)" icon="pi pi-eye" class="p-button-rounded p-button-primary p-button-text mr-2" @click="open_url('https://office.bexio.com/index.php/kontakt/show/id/' + details.customer)" v-tooltip.top="'View Customer in Bexio'" />
                <Button icon="pi pi-search" class="p-button-rounded p-button-primary p-button-text" @click="get_customers(details.id)" v-tooltip.top="'Search customers'" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                Attachments <Badge v-if="details.attachments" :value="String(details.attachments.length)" /><Badge v-if="!details.attachments" :value="String(0)" />
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <i v-if="details.attachments" @click="open_url(details.file_url)" v-tooltip.top="'Invoice PDF'" class="pi pi-file-pdf mr-2 text-primary cursor-pointer" />
                <Button icon="pi pi-upload" class="p-button-rounded p-button-primary p-button-text" @click="upload_attachments(details)" v-tooltip.top="'Upload attachments'" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                Banking
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0 align-content-center flex-wrap">
                <Avatar v-if="details.banking" icon="pi pi-check" class="mr-2 bg-green-600 text-white mr-2 mb-2" shape="circle" v-tooltip.top="'Linked with bank transfer'" />
                <Avatar v-if="!details.banking" icon="pi pi-times" class="mr-2 bg-red-500 text-white mr-2 mb-2" shape="circle" v-tooltip.top="'Not yet linked with bank transfer'" />
                <Button icon="pi pi-search" class="p-button-rounded p-button-primary p-button-text" @click="get_bank_transactions(details.id)" v-tooltip.top="'Search bank transactions'" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 align-content-center">
                ERP
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0 align-content-center flex-wrap">
                <Avatar v-if="details.bexio_id" icon="pi pi-check" class="mr-2 bg-green-600 text-white mr-2 mb-2" shape="circle" v-tooltip.top="'Linked with ERP'" />
                <Avatar v-if="!details.bexio_id" icon="pi pi-times" class="mr-2 bg-red-500 text-white mr-2 mb-2" shape="circle" v-tooltip.top="'Not yet linked with ERP!'" />
                <Button v-if="details.bexio_id" icon="pi pi-eye" class="p-button-rounded p-button-primary p-button-text" @click="open_url('https://office.bexio.com/index.php/kb_invoice/show/id/' + details.bexio_id)" v-tooltip.top="'View invoice in Bexio'" />
                <Button v-if="!details.bexio_id" icon="pi pi-sync" class="p-button-rounded p-button-primary p-button-text" @click="console.log(details)" v-tooltip.top="'Search bank transactions'" />
            </div>
            <div class="col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Partner
            </div>
            <div class="col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span>{{details.partner}}</span>
            </div>
        </div>
    </Sidebar>
    <!-- BANK TRANSACTIONS -->
    <Sidebar v-model:visible="bank_transactions_slider" position="left" style="width: 50%">
        <div class="col-12 formgrid grid">
            <Toolbar class="mt-2 w-full">
                <template #start>
                    <span class="p-input-icon-left" style="width: 400px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_bank_transactions['global'].value" placeholder="Search" style="width: 400px" />
                    </span>
                </template>
            </Toolbar>
            <div rounded class="overflow-hidden shadow-3 border-200 mt-4 w-full">
                <DataTable class="w-full" v-model:filters="filter_bank_transactions" :value="bank_transactions" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,500]" :paginator="true">
                    <Column field="is_valid_from" header="Date" sortable>
                        <template #body="slotProps">
                            {{ format_swiss_date(slotProps.data.date) }}
                        </template>
                    </Column>
                    <Column field="description" header="Description" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{ slotProps.data.description }}</span>
                        </template>
                    </Column>
                    <Column field="amount" header="Amount" sortable>
                        <template #body="slotProps">
                            {{ format_currency(slotProps.data.amount) }}
                        </template>
                    </Column>
                    <Column style="width: 150px">
                        <template #body="slotProps">
                            <Button v-if="!transaction_id_exists(slotProps.data.id)" icon="pi pi-plus" class="p-button-rounded p-button-primary mb-2" @click="link_bank_transaction(slotProps.data.id, slotProps.data.date, invoice_id)" v-tooltip.top="'Link transaction'" />
                            <Avatar v-if="transaction_id_exists(slotProps.data.id)" icon="pi pi-check" class="mr-2 bg-green-600 text-white mr-2 mb-2" shape="circle" v-tooltip.top="'Transaction is linked'" />
                            <Button v-if="transaction_id_exists(slotProps.data.id)" icon="pi pi-plus" class="p-button-rounded bg-orange-600 border-orange-500 text-white" @click="link_bank_transaction(slotProps.data.id, slotProps.data.date, invoice_id)" v-tooltip.top="'Link transaction again'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </Sidebar>
    <!-- CUSTOMER TRANSACTIONS -->
    <Sidebar v-model:visible="customers_slider" position="left" style="width: 50%">
        <div class="col-12 formgrid grid">
            <Toolbar class="mt-2 w-full">
                <template #start>
                    <span class="p-input-icon-left" style="width: 400px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_customers['global'].value" placeholder="Search" style="width: 400px" />
                    </span>
                </template>
            </Toolbar>
            <div rounded class="overflow-hidden shadow-3 border-200 mt-4 w-full">
                <DataTable class="w-full" v-model:filters="filter_customers" :value="customers" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,500]" :paginator="true">
                    <Column field="name" header="Name" sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{ slotProps.data.name }} ({{ String(slotProps.data.id).padStart(5,'0') }})</span>
                        </template>
                    </Column>
                    <Column field="address" header="Address" sortable>
                        <template #body="slotProps">
                            <div>{{ slotProps.data.address }}</div>
                            <div>{{ slotProps.data.zip }} {{ slotProps.data.city }}</div>
                        </template>
                    </Column>
                    <Column field="zip" header="Zip" hidden />
                    <Column field="city" header="City" hidden />
                    <Column field="id" header="Id" hidden />
                    <Column style="width: 150px">
                        <template #body="slotProps">
                            <Button v-if="customer_id != slotProps.data.id" icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2" @click="open_url('https://office.bexio.com/index.php/kontakt/show/id/' + slotProps.data.id)" v-tooltip.top="'View customer in Bexio'" />
                            <Button v-if="customer_id != slotProps.data.id" icon="pi pi-sync" class="p-button-rounded p-button-primary mr-2" @click="link_customer_invoice(slotProps.data.id, transaction_id)" v-tooltip.top="'Select customer'" />
                            <Avatar v-if="customer_id == slotProps.data.id" icon="pi pi-check" class="mr-2 bg-green-600 text-white mr-2" shape="circle" v-tooltip.top="'Customer is linked'" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </Sidebar>
</template>