<script setup>
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import { FilterMatchMode } from 'primevue/api'
import axios from 'axios'

const filter_bobs = ref({global: {value: '', matchMode: FilterMatchMode.CONTAINS}})

/////////////////////
// Interface Props //
/////////////////////

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const database = ref(null)
const databases = ref([])
const mandate = ref(null)
const mandates = ref([])
const category = ref(null)
const categories = ref([])
const bob = ref(null)
const bobs = ref([])
const selected_bobs = ref([])
const edit_bob_id = ref(null)
const editor_color = ref("#ffebcd")
const editor_error = ref(false)

const edit_bob_slider = ref(false)

onMounted(() => {
    loader.value = true
    console.log("database", database.value, "mandate", mandate.value, "category", category.value)
    database.value = localStorage.getItem('database')
    mandate.value = localStorage.getItem('mandate')
    category.value = localStorage.getItem('category')
    database.value ? get_mandates() : null
    database.value ? get_categories() : null
    mandate.value ? get_bobs() : null
    get_databases()
    loader.value = false
})


const get_databases = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/datahubv2/get-databases')
    .then(response => {
        console.log("databases", response.data)
        databases.value = response.data
        database.value ? get_mandates() : null
    })
}

const get_mandates = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/datahubv2/get-mandates', {db: database.value})
    .then(response => {
        console.log("mandates", response.data)
        mandates.value = response.data
    })
}

const get_categories = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/datahubv2/get-categories', {db: database.value, mandate: mandate.value})
    .then(response => {
        console.log("categories", response.data)
        categories.value = response.data
        get_bobs()
    })
}

const get_bobs = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/datahubv2/get-bobs', {db: database.value, mandate: mandate.value, category: category.value})
    .then(response => {
        console.log("bobs", response.data)
        bobs.value = response.data
    })
}

const change_category = () => {
    localStorage.setItem('category', category.value)
    get_bobs()
    selected_bobs.value = []
}

const change_mandate = () => {
    localStorage.setItem('mandate', mandate.value)
    category.value = null
    get_categories()
    get_bobs()
    selected_bobs.value = []
}

const change_database = () => {
    bobs.value = []
    localStorage.setItem('database', database.value)
    localStorage.setItem('mandate', null)
    localStorage.setItem('category', null)
    mandate.value = null
    category.value = null
    mandates.value = []
    categories.value = []
    get_databases()
    selected_bobs.value = []
}

const copy_to_clipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.add({ severity: 'success', summary: 'Copied to clipboard', detail: text, life: 3000 });
}

const edit_bob = (id) => {
    if (edit_bob_id.value == id) {
        edit_bob_slider.value = !edit_bob_slider.value
    } else {
        edit_bob_id.value = id
        loader.value = true
        axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahubv2/get-bob", {db: database.value, id: edit_bob_id.value})
        .then(response => {
            bob.value = JSON.stringify(response.data, null, "\t")
            edit_bob_slider.value = true
            loader.value = false
        }).catch(error => {
            console.error("There was an error!", error.message)
        })
    }
}

const refresh_db_index = () => {
    confirm.require({
        message: 'Are you sure you want to refresh the DB index of ' + database.value + '?',
        header: 'Refresh DB index',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, refresh index',
        acceptClass: 'p-button-danger',
        rejectLabel: 'No',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahubv2/refresh-index", {db: database.value})
                .then(response => {
                    loader.value = false
                    get_categories()
                    toast.add({ severity: 'success', summary: 'Success', detail: 'DB index for ' + database.value + ' refreshed', life: 3000 });
                }).catch(error => {
                    console.error("There was an error!", error.message);
                });
        }
    })
}

const delete_bob = (id) => {
    confirm.require({
        message: 'Are you sure you want to delete bob: ' + id + '?',
        header: 'Delete Bob',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete it',
        acceptClass: 'p-button-danger',
        rejectLabel: 'No, keep it',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahubv2/delete-bob", {db: database.value, id: id})
                .then(response => {
                    loader.value = false
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Bob ' + id + ' Deleted', life: 3000 });
                }).catch(error => {
                    console.error("There was an error!", error.message);
                });
        }
    })
}

const delete_selected_bobs = () => {
    confirm.require({
        message: 'Are you sure you want to delete ' + selected_bobs.value.length + ' bobs?',
        header: 'Delete Bobs',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete them',
        acceptClass: 'p-button-danger',
        rejectLabel: 'No, keep them',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahubv2/delete-bobs", {db: database.value, ids: selected_bobs.value})
                .then(response => {
                    loader.value = false
                    toast.add({ severity: 'success', summary: 'Success', detail: 'Bobs Deleted', life: 3000 });
                    get_bobs()
                    selected_bobs.value = []
                }).catch(error => {
                    console.error("There was an error!", error.message);
                });
        }
    })
}

const save_bob = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/datahubv2/save-bob", {db: database.value, id: edit_bob_id.value, bob: bob.value})
    .then(response => {
        toast.add({ severity: 'success', summary: 'Bob saved', detail: 'Bob was saved successfully', life: 3000 });
        get_bobs()
        loader.value = false
    }).catch(error => {
        console.error("There was an error!", error.message)
    })
}

const isValidJSONString = (str) => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

watch(bob, () => {
    if (isValidJSONString(bob.value)) {
        editor_error.value = false
        editor_color.value = "#d4edda"
    }
    else {
        editor_error.value = true
        editor_color.value = "#f57e76"
    }
})

const formatKeyName = (str) => {
    let keyValues = str.split(":");
    let id = ""
    let color = ""
    for (let i = 0; i < keyValues.length; i++) {
        if (i == 0){color = "#1c80cf"}
        if (i == 1){color = "#2a602c"}
        if (i > 1){color = "#000000"}
        if (i == 0) {
            id = "<span style='color:" + color + "'>" + keyValues[i] + "</span>"
        } else {
            id = id + "<span style='color:#D32F2F'>:</span><span style='color:" + color + "'>" + keyValues[i] + "</span>"
        }
    }
    return id
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toolbar>
        <template #start>
            <Dropdown placeholder="Select Database" v-model="database" :options="databases" optionLabel="name" optionValue="id" class="w-auto mr-4" @change="change_database()" />
            <Dropdown placeholder="Select Mandate" v-model="mandate" :options="mandates" optionLabel="name" filter optionValue="id" class="w-auto mr-4" @change="change_mandate()">
                <template #option="slotProps">
                    <div>
                        <div class="font-bold">{{slotProps.option.name}} <Badge :value="String(slotProps.option.count)" /></div>
                    </div>
                </template>
            </Dropdown>
            <Dropdown v-if="categories.length > 0" placeholder="Select Category" v-model="category" showClear :options="categories" optionLabel="name" filter optionValue="id" class="w-auto mr-4" @change="change_category()">
                <template #option="slotProps">
                    <div>
                        <div class="font-bold">{{slotProps.option.name}} <Badge :value="String(slotProps.option.count)" /></div>
                    </div>
                </template>
            </Dropdown>
            <Button v-if="database" @click="refresh_db_index()" v-tooltip.top="'Refresh DB Index'" class="p-button-rounded p-button-text mr-2" icon="pi pi-refresh" />
            <Button @click="restore_db()" v-tooltip.top="'Restore a database to restore DB'" class="p-button-rounded p-button-text mr-2" icon="pi pi-cloud-upload" />
        </template>
    </Toolbar>
    <Toolbar v-if="bobs.length > 0" class="mt-2">
        <template #start>
            <span class="p-input-icon-left" style="width: 400px">
                <i class="pi pi-search" />
                <InputText v-model="filter_bobs['global'].value" placeholder="Search results" />
            </span>
        </template>
    </Toolbar>
    <Toolbar v-if="selected_bobs.length > 0" class="mt-2">
        <template #start>
            <div class="mr-4 white-space-nowrap">
                <span class="font-bold mr-2">Selected Bobs:</span>
                <Badge :value="String(selected_bobs.length)" severity="danger"></Badge>
            </div>
            <Button @class="copy_to_database" icon="pi pi-copy" label="Copy to DB" class="mr-2 white-space-nowrap" />
            <Button @click="download_selected_bobs" icon="pi pi-download" label="Download" class="mr-2 white-space-nowrap" />
            <Button @click="delete_selected_bobs" icon="pi pi-trash" label="Delete Bobs" class="p-button-danger mr-2 white-space-nowrap" />
        </template>
    </Toolbar>
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <DataTable v-model:selection="selected_bobs" v-model:filters="filter_bobs" :value="bobs" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50, 100, 200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="id" header="Key" sortable>
                <template #body="slotProps">
                    <div class="flex items-center">
                        <Avatar icon="pi pi-key" class="mr-2" style="background-color:#D32F2F; color: #ffffff" shape="circle" />
                        <span class="font-bold mr-2" v-html="formatKeyName(slotProps.data.id)"></span>
                        <Button @click="copy_to_clipboard(slotProps.data.id)" v-tooltip.top="'Copy key name to clipboard'" class="p-button-rounded p-button-text" icon="pi pi-copy" />
                        <Button @click="edit_bob(slotProps.data.id)" v-tooltip.top="'Edit Bob'" class="p-button-rounded p-button-text" icon="pi pi-pencil" />
                        <Button @click="download_bob(slotProps.data.id)" v-tooltip.top="'Download Bob'" class="p-button-rounded p-button-text" icon="pi pi-download" />
                        <Button @click="delete_bob(slotProps.data.id)" v-tooltip.top="'Delete Bob'" class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash" />
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <Sidebar header="Bob editor" v-model:visible="edit_bob_slider" position="right" style="width: 80%">
        <div class="col-12 formgrid grid">
            <div class="field col-12">
                <div class="w-full mt-2 shadow-2 bg-white p-2" style="position: sticky; top: 0" v-if="!editor_error">
                    <Button @click="save_bob" icon="pi pi-save" label="Save Bob" class="p-button-success mr-3 white-space-nowrap" />
                    <Button @click="copy_to_clipboard(bob)" v-tooltip.top="'Copy JSON contents to clipboard'" class="p-button-rounded p-button-text" icon="pi pi-copy" />
                </div>
                <div class="w-full mt-2 shadow-2" v-if="editor_error" style="background-color: #f8d7da; color: #721c24; padding: 10px; position: sticky; top: 0">
                    <span class="font-bold">Error:</span> The JSON is not valid
                </div>
                <Textarea id="bob" :autoResize="true" rows="12" v-model="bob" class="w-full mt-4" :style="'font-family: consolas; background-color: #1e1e1e; color: '+editor_color" />
            </div>
        </div>
    </Sidebar>
</template>