<script setup>
import { ref, onMounted, watch } from 'vue'
import axios from "axios"

const props = defineProps({
  pdf_url: { type: String }
})

// fuck fuck fuck fuck fuck fuck why no work :(
const getPDFPath = (url) => {
    axios.get(url, { responseType: "blob"})
    .then(response => {
        return window.URL.createObjectURL(new Blob([response.data]))
    })
    .catch(console.error);
}

</script>
<template>
    <div>
        <iframe :src="props.pdf_url + '#' + 'zoom=FitW'" style="width: 100%; height: 100vh; border: none;">
            Oops! an error has occurred.
        </iframe>
    </div>
</template>