<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_finances = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const finances = ref([])
const details = ref()
const search_term = ref('')
const result_count = ref(100)
const invoice_details_slider = ref(false)
const customer_filter = ref(null)
const customers = ref([])
const show_paid = ref(false)
const total_amount = ref(0)
const total_payout = ref(0)

const partner_commission = ref(10)

const result_counts = [
    {label: '200', value: 100},
    {label: '200', value: 200},
    {label: '1000', value: 1000},
    {label: '2000', value: 2000},
    {label: '5000', value: 5000},
    {label: '10000', value: 10000},
]

onMounted( async () => {
    get_finances()
    get_customers()
})

const get_finances = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/partner/get-finances', {search: search_term.value, limit: result_count.value, show_paid: show_paid.value})
    .then(response => {
        console.log(response.data)
        finances.value = response.data
        loader.value = false
        calculate_totals()
    })
}

const get_customers = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/partner/get-customers', {mandate: 'impact'})
    .then(response => {
        console.log("customers", response.data)
        customers.value = response.data
    })
}

const invoice_details = (e) => {
    console.log(e.data)
    loader.value = true
    details.value = e.data
    invoice_details_slider.value = true
    loader.value = false
}

const calculate_totals = () => {
    let amount = 0
    let payout = 0
    finances.value.forEach((finance) => {
        amount += parseFloat(finance.amount, 2)
        payout += (((finance.amount  - ((finance.amount / 100) * 8.1)) / 100) * partner_commission.value)
    })
    total_amount.value = amount
    total_payout.value = payout
    console.log("total_amount", total_amount.value, "total_payout", total_payout.value)
}

const get_customer_name = (contact_id) => {
    let customer = customers.value.find(customer => customer.id == contact_id)
    return customer ? customer.name : ''
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_swiss_date_time = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4) + ' ' + value.substring(11,16)
}

const open_url = (url) => {
    window.open(url,'_blank')
}

const copy_to_clipboard = (value) => {
    navigator.clipboard.writeText(value)
    toast.add({severity:'success', summary: 'Erfolgreich', detail: 'In Zwischenablage kopiert', life: 3000});
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>


<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <Toolbar>
            <template #start>
                <div class="flex align-items-start align-items-center">
                    <span class="p-input-icon-left mr-2" style="width: 300px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_finances['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
                    </span>
                    <Button label="Suchen" icon="pi pi-search" class="mr-2" @click="get_finances()" style="width: 170px" />
                    <Dropdown @change="get_finances()" v-model="result_count" :options="result_counts" optionLabel="label" optionValue="value" placeholder="Resultate" style="width: 150px" class="mr-2" />
                    <MultiSelect v-model="customer_filter" :options="customers" optionLabel="name" optionValue="id" placeholder="Customers" style="width: 250px" class="mr-2" />
                    <div style="width: 400px" class="flex align-items-center">
                        <Checkbox v-model="show_paid" :binary="true" class="mr-2" @change="get_finances()" /><span class="white-space">Show paid entries</span>
                    </div>
                </div>
            </template>
        </Toolbar>
        <div rounded class="overflow-hidden shadow-3 border-200 mt-4">
            <DataTable :value="finances" @row-click="invoice_details" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,500,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                <Column field="date" header="Date" sortable>
                    <template #body="slotProps">
                        <span>{{format_swiss_date(slotProps.data.date)}}</span>
                    </template>
                </Column>
                <Column field="invoice_name" header="Invoice" sortable>
                    <template #body="slotProps">
                        <Chip class="pl-0 pr-3 mr-2">
                            <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-file" /></span>
                            <span class="ml-2 font-medium">{{slotProps.data.invoice_name}}</span>
                        </Chip>
                        <span class="font-bold mr-2">{{get_customer_name(slotProps.data.customer)}}</span>
                    </template>
                </Column>
                <Column field="status" header="Status" sortable>
                    <template #body="slotProps">
                        <Avatar v-if="slotProps.data.status == 2" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="'Beteiligung wurde '+slotProps.data.payout_date+' ausbezahlt'" />
                        <Avatar icon="pi pi-times" class="mr-2 bg-red-500 text-white" shape="circle" v-tooltip.top="'Beteiligung noch nicht ausbezahlt'" />
                    </template>
                </Column>
                <Column field="status" header="Partner share" sortable>
                    <template #body="slotProps">
                        <span>{{partner_commission}}% {{ slotProps.data.partner_commission }}</span>
                    </template>
                </Column>
                <Column field="amount" header="Amount" sortable>
                    <template #body="slotProps">
                        <span>{{format_currency(slotProps.data.amount)}}</span>
                    </template>
                </Column>
                <Column header="Partner payout" sortable>
                    <template #body="slotProps">
                        <span>{{format_currency((((slotProps.data.amount - ((slotProps.data.amount / 100) * 8.1)) / 100) * partner_commission))}}</span>
                    </template>
                </Column>
                <ColumnGroup type="footer">
                    <Row>
                        <Column footer="Total:" :colspan="4" footerStyle="text-align:right" />
                        <Column :footer="format_currency(total_amount)" />
                        <Column :footer="format_currency(total_payout)" />
                    </Row>
                </ColumnGroup>
            </DataTable>
        </div>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Sidebar v-model:visible="invoice_details_slider" position="right" style="width: 60rem">
        <div class="col-12 formgrid grid">
            <div class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <Image preview class="w-12rem" :src="details.preview_url" />
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Invoice Nr.
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span class="font-bold">{{details.invoice_name}}</span>
                <i @click="copy_to_clipboard(details.sku)" v-tooltip.top="'Copy Invoice-Nr to clipboard'" class="pi pi-copy ml-4 text-primary cursor-pointer" />
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Invoice date
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span>{{format_swiss_date(details.date)}}</span>
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Customer
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span>{{get_customer_name(details.customer)}} ({{ String(details.customer).padStart(5, '0') }})</span>
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Details
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                {{ details.description }}
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Amount
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span>{{format_currency(details.amount)}}</span>
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Partner share
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span>{{format_currency(partner_commission)}}</span>
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Partner payout
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <span>{{format_currency(((details.amount - ((details.amount / 100) * 8.1)) / 100) * partner_commission)}}</span>
            </div>
            <div class="field col-3 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Status
            </div>
            <div class="field col-9 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Avatar v-if="details.status == 2" icon="pi pi-check" class="mr-2 bg-green-600 text-white" shape="circle" v-tooltip.top="'Beteiligung wurde '+details.payout_date+' ausbezahlt'" />
                <Avatar icon="pi pi-times" class="mr-2 bg-red-500 text-white" shape="circle" v-tooltip.top="'Beteiligung noch nicht ausbezahlt'" />
            </div>
        </div>
        <template #header>
            <div class="flex">
                <button class="p-sidebar-icon p-link mr-2">
                    <span class="pi pi-print" />
                </button>
            </div>
        </template>
    </Sidebar>
</template>